import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { apiClientService } from '../../domain/services/apiClientService';
import { t } from '../../domain/services/configService';
import { theme } from '../../domain/theme';
import { SmartLink } from '../SmartLink';
import { facePaint } from '../../domain/theme/utils/facePaint';
import { CardItemVerticalLegacy } from '../responsive/organisms/CardItemVerticalLegacy';
import {
  mapContentToCardData,
  removeAttributesFromCardData,
  removeAuthorsAndDateFromCardData,
  removeTaxonomyElementsFromCardData
} from '../../domain/utils/contentUtils';
import { removeDuplicates } from '../../domain/utils/contentUtils/removeDuplicates';

export const MostPopularContent = ({ location }) => {
  const [mpcContent, setMpcContent] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const url = `/ajax/most-popular-contents.json`;
      const { response } = await apiClientService.getJson(url);
      if (response.data?.content?.entities) {
        let cards = response.data.content.entities;

        // Remove duplicated content.
        const dataToBeDeduplicated = cards.map(card => ({ content: card }));
        const deduplicatedData = removeDuplicates(dataToBeDeduplicated);
        cards = [];
        deduplicatedData.forEach(data => cards.push(data.content));

        for (let i = cards.length - 1; i > 0; i -= 1) {
          const j = Math.floor(Math.random() * i);
          const temp = cards[i];
          cards[i] = cards[j];
          cards[j] = temp;
        }
        setMpcContent(cards.slice(0, 3));
      }
    };
    fetchData();
  }, []);
  const mustShowComponent = mpcContent && mpcContent.length > 0;

  const style = {
    mostPopularWrapper: css`
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: flex-start;
      align-content: flex-stretch;
      flex-direction: column;
      margin: 0 -30px ${theme.spacing('base')} -30px;
      max-width: 414px;
      background-color: #f2f2f2;
      @media (min-width: 415px) {
        margin: 0 auto ${theme.spacing('base')} auto;
      }
      padding: 0 15px;
      ${theme.mq.small_desktop} {
        margin: 0 0 ${theme.spacing('base')} 0;
        padding: 0 ${theme.spacing('base')};
      }
      ${theme.mq.tablet} {
        max-width: unset;
      }
      ${location === 'main'
        ? `${theme.mq.small_desktop} { display:none;}`
        : `${theme.mqMax.tablet} { display:none;}`}
      content-visibility: auto;
      clear: both;
      ${facePaint({
        'contain-intrinsic-size': ['0 595px', '0 403px', '0 1031px', '0 1031px']
      })};
    `,

    mostPopularH3: css`
      position: relative;
      height: 24px;
      ${theme.font.family('boldWeb')};
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 3px;
      text-align: center;
      color: #000000;
      margin: ${theme.spacing('l')} auto ${theme.spacing('base')} auto;
      text-transform: uppercase;
      ::before,
      ::after {
        content: '';
        position: absolute;
        width: 48px;
        height: 1px;
        top: 12px;
        border: solid 1px #999999;
        ${theme.mq.small_desktop} {
          width: 40px;
        }
      }
      ::before {
        left: -65px;
        ${theme.mq.small_desktop} {
          left: -54px;
        }
      }
      ::after {
        right: -65px;
        ${theme.mq.small_desktop} {
          right: -54px;
        }
      }
    `,

    mostPopularUl: css`
      list-style-type: none;
      @media (min-width: 768px) and (max-width: 1023px) {
        display: flex;
        justify-content: space-around;
        width: 100%;
      }
    `,

    mostPopularLi: css`
      list-style: none !important;
      @media (min-width: 768px) and (max-width: 1023px) {
        width: 30%;
      }
    `,

    mostPopularMore: css`
      height: 18px;
      padding: 0 10px;
      text-transform: uppercase;
      display: block;
      margin: 18px auto 40px auto;
      ${theme.font.family('boldWeb')};
      font-size: 12px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 3px;
      text-align: center;
      color: #000000;
      border-bottom: solid 2px #ffcc00;
    `
  };
  const gtmClickEvent = {
    event: 'click_on_most_popular'
  };
  return (
    <div css={style.mostPopularWrapper}>
      <h3 css={style.mostPopularH3}>{t('most popular')}</h3>
      <ul css={style.mostPopularUl}>
        {mustShowComponent &&
          mpcContent.map(content => {
            return (
              <li css={style.mostPopularLi} key={content.id}>
                <CardItemVerticalLegacy
                  data={removeAttributesFromCardData(
                    removeTaxonomyElementsFromCardData(
                      removeAuthorsAndDateFromCardData(
                        mapContentToCardData(content)
                      )
                    ),
                    ['bgImageEntity']
                  )}
                  aspect="4x3"
                  size="list"
                  imageMaxWidths={{
                    mobile: 160,
                    tablet: 160,
                    small_desktop: 236,
                    large_desktop: 236
                  }}
                  gtmClickEvent={gtmClickEvent}
                />
              </li>
            );
          })}
      </ul>
      <SmartLink css={style.mostPopularMore} to="/">
        {t('view more')}
      </SmartLink>
    </div>
  );
};

MostPopularContent.propTypes = { location: PropTypes.string };
MostPopularContent.defaultProps = { location: 'main' };
