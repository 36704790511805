const { getCardImage } = require('./card/cardUtils');

const removeDuplicatesByKey = (data, keyGetter) => {
  const itemsByKey = new Map();

  data.forEach((element, index) => {
    const key = keyGetter(element);
    if (key) {
      const keyedData = { originalIndex: index, ...element };
      if (!itemsByKey.has(key)) {
        itemsByKey.set(key, new Set());
      }
      itemsByKey.get(key).add(keyedData);
    }
  });

  itemsByKey.forEach(itemSet => {
    if (itemSet.size > 1) {
      // Remove all Photo Galleries, ensuring we don't remove all
      // items in the case of all items being Photo Galleries.
      itemSet.forEach(item => {
        const type = item.content.bundle || item.content.type;
        if (itemSet.size > 1 && type === 'photo_gallery') {
          itemSet.delete(item);
        }
      });
    }
  });

  const validResults = [];
  itemsByKey.forEach(itemSet => {
    itemSet.forEach(item => {
      validResults.push(item);
    });
  });

  const results = validResults.sort(
    (a, b) => a.originalIndex - b.originalIndex
  );

  results.forEach(result => {
    // eslint-disable-next-line no-param-reassign
    delete result.originalIndex;
  });

  return results;
};

const removeDuplicates = data => {
  // Remove duplicated images.
  const imageProcessedData = removeDuplicatesByKey(
    data,
    element => getCardImage(element.content)?.mediaImage?.url
  );
  // Remove duplicated titles.
  return removeDuplicatesByKey(
    imageProcessedData,
    element => element.content.promoTitle || element.content.title
  );
};

module.exports = { removeDuplicates };
